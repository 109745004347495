import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import TopTabs from '@components/common/TopTabs'
import ProgramPromo from '@components/layout/ProgramPromo'
import PortalsExport from '@components/layout/PortalsExport'
import PortalsPromo from '@components/layout/PortalsPromo'
import { Link, Script } from 'gatsby'
import Modal from '../common/Modal'
import ArrowRight from '@assets/icons/arrow_right_sm.svg'
import Faq from "@components/pages/program-crm/Faq";

const pathsWithoutPortalsExport = [
    '/certyfikat-ssl/',
    '/certyfikat-ssl',
    '/domena-i-poczta-email/',
    '/domena-i-poczta-email',
    '/program-crm/eksport-do-portali/',
    '/program-crm/eksport-do-portali',
    '/aplikacja-mobilna/',
    '/aplikacja-mobilna',
    '/pomoc/imo/',
    '/pomoc/imo',
    '/pomoc/panel-www/',
    '/pomoc/panel-www',
    '/program-crm/funkcje/',
    '/program-crm/funkcje',
    '/program-crm/korzysci/',
    '/program-crm/korzysci',
    '/strona-www/korzysci/',
    '/strona-www/korzysci',
    '/formularz-zamowienia/',
    '/formularz-zamowienia',
    '/zamowienie/potwierdzenie/',
    '/zamowienie/potwierdzenie',
    '/zdjecia-kategorie/',
    '/zdjecia-kategorie',
    '/program-lojalnosciowy-polecaj-imo/',
    '/program-lojalnosciowy-polecaj-imo',
]

const pathsWithPortalsPromo = ['/cennik/', '/program-crm/']

const pathsWithoutTabs = [
    '/404/',
    '/404',
    '/zdjecia-kategorie/',
    '/zdjecia-kategorie',
    '/pomoc/imo/',
    '/pomoc/imo',
    '/pomoc/panel-www/',
    '/pomoc/panel-www',
    '/program-crm/funkcje/',
    '/program-crm/funkcje',
    '/program-crm/korzysci/',
    '/program-crm/korzysci',
    '/strona-www/korzysci/',
    '/strona-www/korzysci',
]

const pathsWithoutModal = ['/formularz-zamowienia/', '/zamowienie/potwierdzenie/', '/formularz-zamowienia', '/zamowienie/potwierdzenie']
const pathsWithFaq = ["/program-crm/"];
const pathsWithoutTopAndFooter = ['/zdjecia-kategorie/', '/zdjecia-kategorie']

const Layout = ({ children }) => {
  const path = children.props.location.pathname;
  const home = path === "/";
  return (
    <>
      {!pathsWithoutTopAndFooter.includes(path) && (
        <Navbar home={home} path={`${path}${children.props.location.hash}`} />
      )}
      <div className="flex flex-col justify-between min-h-screen relative overflow-hidden">
        <main className="container mx-auto px-4 min-h-screen">
          {!home &&
            !path?.includes("/aktualnosci/") &&
            !path?.includes("/poradnik-posrednika/") &&
            !path?.includes("/slownik-pojec/") &&
            !path?.includes("/baza-posrednikow/") &&
            !pathsWithoutTabs.includes(path) && <TopTabs path={path} />}
          {children}
          {!home &&
            !pathsWithoutPortalsExport.includes(path) &&
            !path.includes("/strona-www/") && <PortalsExport />}
          {!home && pathsWithPortalsPromo.includes(path) && (
            <>
              <div class="flex gap-4 flex-wrap">
                <div class="lg:w-[calc(50%-0.5rem)] bg-soft-grey py-10 px-6 lg:p-[86px] rounded-[32px]">
                  <PortalsPromo />
                </div>
                <div class="lg:w-[calc(50%-0.5rem)] bg-[#FDF2E6] py-10 px-6 lg:p-[86px] rounded-[32px]">
                  <ProgramPromo />
                </div>
                <div className="w-full text-center mb-10 lg:mb-[86px]">
                  <Link
                    className="text-blue hover:underline cursor-pointer flex items-center justify-center"
                    to="/program-crm/rabaty-w-portalach/"
                  >
                    Zobacz rabaty w innych portalach{" "}
                    <ArrowRight className="ml-2" />
                  </Link>
                </div>
              </div>
            </>
          )}
          {pathsWithFaq.includes(path) && <Faq />}
        </main>
        {!pathsWithoutTopAndFooter.includes(path) && <Footer />}
      </div>
      {!pathsWithoutModal.includes(path) && <Modal />}
      <Script
        src="https://code.tidio.co/wyuaunpdypyuuk1iezi802tosct7qzte.js"
        async
      />
    </>
  );
};

export default Layout

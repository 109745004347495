import React, { Fragment } from "react";
import EclipseOrangeImg from "@images/footer/eclipse_orange.svg";
import Logo from "@images/logo_white.svg";
import Facebook from "@images/footer/facebook.svg";
import IMOButton from "../common/IMOButton";
import {
  navigation_footer,
  navigation_footer_mobile,
} from "./utils/NavbarData";
import { Link } from "gatsby";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="relative overflow-hidden bg-navy-blue lg:bg-transparent">
      <div className="absolute h-full footer-eclipse-background z-[-1]" />
      <EclipseOrangeImg className="absolute top-1 hidden lg:block" />
      <div className="w-full space-y-6 pt-16 lg:w-[44rem] text-center text-white flex flex-col items-center mx-auto">
        <h2>Narzędzia do kompleksowej obsługi biura nieruchomości</h2>
        <div className="flex items-center">
          <Link to="/program-crm/bezplatna-wersja-demo/">
            <IMOButton variant="primary" className="mr-4">
              Wypróbuj za darmo
            </IMOButton>
          </Link>
          <Link to="/formularz-zamowienia/">
            <IMOButton variant="secondary-white">Zamów</IMOButton>
          </Link>
        </div>
      </div>
      <div className="pt-16 flex justify-between flex-col px-4 pb-7 xs:px-10 lg:pb-10 xl:px-24 xl:pb-16 lg:flex-row">
        <div className="order-4 lg:order-[unset] flex flex-col items-start text-off-white">
          <Link to="/">
            <Logo className="max-w-[90px] md:max-w-[unset] mb-2 mt-8 lg:mb-14 lg:mt-0" />
          </Link>
          <div className="flex justify-between w-full flex-wrap lg:block">
            <div className="mr-6 xs:mr-0 w-[calc(50%-12px)] xs:w-1/2 lg:w-auto">
              <p className="text-xs lg:text-sm">ul. Drzewieckiego 7/21</p>
              <p className="text-xs lg:text-sm">87-100 Toruń</p>
            </div>
            <br />
            <div className="w-[calc(50%-12px)] xs:w-1/2 lg:w-auto">
              <p className="text-xs lg:text-sm">NIP: 9562209543</p>
              <p className="text-xs lg:text-sm">REGON: 340370587</p>
              <p className="text-xs lg:text-sm">KRS: 0000290593</p>
            </div>
            <div className="w-full mt-4">
              <a href="https://www.facebook.com/ProgramIMO">
                <Facebook />
              </a>
            </div>
          </div>
        </div>
        <span className="hidden lg:block h-auto opacity-20 w-[1px] bg-off-white"></span>
        <div className="hidden order-1 lg:order-[unset] lg:flex flex-wrap flex-grow-[0.3] items-start justify-between text-off-white">
          {navigation_footer.map((i) => (
            <div
              key={i.to}
              className="inline-flex flex-col items-start text-off-white"
            >
              <Link
                to={i.to}
                className="uppercase font-extrabold text-xs lg:text-sm text- py-2 mb-3"
              >
                {i.label}
              </Link>
              {i.sections.map((section, idx) => (
                <Fragment key={idx}>
                  {section?.to?.includes("#") ? (
                    <Link
                      key={section.to}
                      to={section.to}
                      className="text-sm lg:text-base py-2"
                    >
                      {section.label}
                    </Link>
                  ) : section.href ? (
                    <a
                      key={section.href}
                      href={section.href}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      className="text-sm lg:text-base py-2"
                    >
                      {section.label}
                    </a>
                  ) : (
                    <Link
                      key={section.to}
                      to={section.to}
                      className={`text-sm lg:text-base py-2 ${
                        section.class ?? ""
                      }`}
                    >
                      {section.label}
                    </Link>
                  )}
                </Fragment>
              ))}
            </div>
          ))}
        </div>
        <div className="order-1 lg:order-[unset] flex lg:hidden flex-wrap flex-grow-[0.3] items-start justify-between text-off-white">
          {navigation_footer_mobile.map((i) => (
            <div
              key={i.to}
              className="inline-flex flex-col items-start text-off-white first:mr-6 xs:first:mr-0 w-[calc(50%-12px)] xs:w-1/2"
            >
              <Link
                to={i.to}
                className="uppercase font-extrabold text-xs lg:text-sm text- py-2 mb-3"
              >
                {i.label}
              </Link>
              {i.sections.map((section, idx) => (
                <Fragment key={idx}>
                  {section.sections?.length > 0 ? (
                    <>
                      <Link
                        to={section.to}
                        className="uppercase font-extrabold text-xs lg:text-sm text- py-2 mb-3"
                      >
                        {section.label}
                      </Link>
                      {section.sections.map((innerSection, idx) => (
                        <Fragment key={idx}>
                          {innerSection.href ? (
                            <a
                              key={innerSection.href}
                              href={innerSection.href}
                              target="_blank"
                              rel="noopener noreferrer nofollow"
                              className="text-sm lg:text-base py-2"
                            >
                              {innerSection.label}
                            </a>
                          ) : (
                            <Link
                              key={idx}
                              to={innerSection.to}
                              className="text-sm lg:text-base py-2"
                            >
                              {innerSection.label}
                            </Link>
                          )}
                        </Fragment>
                      ))}
                    </>
                  ) : (
                    <Link to={section.to} className="text-sm lg:text-base py-2">
                      {section.label}
                    </Link>
                  )}
                </Fragment>
              ))}
            </div>
          ))}
        </div>
        <span className="hidden lg:block h-auto opacity-20 w-[1px] bg-off-white"></span>
        <div className="mt-8 lg:mt-0 order-3 lg:order-[unset] flex flex-wrap items-end justify-between lg:justify-between lg:flex-col lg:items-start text-off-white">
          <div className="flex items-start flex-col gap-4 lg:mb-4 mr-6 xs:mr-0 w-[calc(50%-12px)] xs:w-1/2 lg:w-auto">
            <h2 className="text-2xl whitespace-nowrap">Biuro sprzedaży</h2>
            <IMOButton
              tag="a"
              variant="secondary-white"
              href="tel:+48 22 201 06 74"
              type=""
              onClick={() => {
                typeof window !== "undefined" &&
                  window.gtag("event", "conversion", {
                    send_to: "AW-979605268/A4ZRCKKnj48ZEJSujtMD",
                  });
              }}
            >
              +48 22 201 06 74
            </IMOButton>
            <IMOButton
              tag="a"
              variant="secondary-white"
              href="tel:+48 56 475 44 79"
              type=""
              onClick={() => {
                typeof window !== "undefined" &&
                  window.gtag("event", "conversion", {
                    send_to: "AW-979605268/A4ZRCKKnj48ZEJSujtMD",
                  });
              }}
            >
              +48 56 475 44 79
            </IMOButton>
            <IMOButton
              tag="a"
              variant="secondary-white"
              href="mailto:imo@imo.pl"
              type=""
              onClick={() => {
                typeof window !== "undefined" &&
                  window.gtag("event", "conversion", {
                    send_to: "AW-979605268/m1HiCMO7iY8ZEJSujtMD",
                  });
              }}
            >
              imo@imo.pl
            </IMOButton>
          </div>
          <div className="flex items-start flex-col w-[calc(50%-12px)] xs:w-1/2 lg:w-auto">
            <h3 className="text-sm font-extrabold">POMOC TECHNICZNA</h3>
            <p className="text-xs mb-4 mt-1">Pon - Pt: 09:00 - 17:00</p>
            <IMOButton
              className="mb-4"
              tag="a"
              variant="secondary-white"
              href="tel:+48 22 203 62 03"
              type=""
              onClick={() => {
                typeof window !== "undefined" &&
                  window.gtag("event", "conversion", {
                    send_to: "AW-979605268/A4ZRCKKnj48ZEJSujtMD",
                  });
              }}
            >
              +48 22 203 62 03
            </IMOButton>
            <IMOButton
              tag="a"
              variant="secondary-white"
              href="mailto:pomoc@imo.pl"
              type=""
              onClick={() => {
                typeof window !== "undefined" &&
                  window.gtag("event", "conversion", {
                    send_to: "AW-979605268/m1HiCMO7iY8ZEJSujtMD",
                  });
              }}
            >
              pomoc@imo.pl
            </IMOButton>
          </div>
        </div>
      </div>
      <div className="px-4 xs:px-10 xl:px-24 text-off-white flex items-center">
        <p className="text-sm">Copyright © {new Date().getFullYear()} IMO</p>
        <Link to="/pomoc/regulaminy" className="text-sm ml-[21px] xs:ml-9 p-2">
          Regulaminy
        </Link>
        <Link
          to="/polityka-prywatnosci"
          className="text-sm ml-[21px] xs:ml-6 p-2"
        >
          Polityka prywatności
        </Link>
      </div>
    </footer>
  );
};

export default Footer;

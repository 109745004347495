import { Link } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import ArrowSm from '@assets/icons/arrow_right_sm.svg'
import PropTypes from 'prop-types'

const upperCaseWord = ['ssl', 'crm']

const breadcrumbsNames = {
  "polityka prywatnosci": "Polityka prywatności",
  "bezplatna wersja demo": "Bezpłatna wersja demo",
  "zamow strone": "Zamów strone",
  "dane agentow i agencji": "Dane agentów i agencji",
  "grupy uprawnien": "Grupy uprawnień",
  "konfiguracja statusow": "Konfiguracja statusów",
  "zarzadzanie lokalizacjami": "Zarządzanie lokalizacjami",
  "dodawanie tagow": "Dodawanie tagów",
  "edycja tresci": "Edycja treści",
  "edycja tresci podstron": "Edycja treści podstron",
  "zarzadzanie plikami": "Zarządzanie plikami",
  "zarzadzanie podstronami": "Zarządzanie podstronami",
  "zmiana danych dostepowych": "Zmiana danych dostępowych",
  "edycja modulow": "Edycja modułów",
  "modul aktualnosci": "Moduł aktualności",
  "modul galerii": "Moduł galerii",
  "modul odnosnikow": "Moduł odnośników",
  "zarzadzanie strona": "Zarządzanie stroną",
  "modul fakturowania": "Moduł fakturowania",
  "praca z baza klientow": "Praca z bazą klientów",
  "praca z baza ofert": "Praca z bazą ofert",
  "zarzadzanie biurem": "Zarządzanie biurem",
  "zrodla ofert": "Źródła ofert",
  "glowne okno programu": "Główne okno programu",
  "lista eksportow oferty": "Lista eksportów oferty",
  "wysylanie ofert na email": "Wysyłanie ofert na e-mail",
  "zdjecia oferty": "Zdjęcia oferty",
  korzysci: "Korzyści",
  "dla posrednika": "Dla pośrednika",
  "dla wlasciciela biura i dewelopera": "Dla własciciela biura i dewelopera",
  "dodatkowe moduly": "Dodatkowe moduły",
  "dostepne projekty": "Dostępne projekty",
  "dla klientow": "Dla klientów",
  "dla wlasciciela biura": "Dla właściciela biura",
  "zamow aplikacje": "Zamów aplikacje",
  "zamow poczte": "Zamów poczte",
  "domena i poczta email": "Domena i poczta e-mail",
  "1 miesiac": "1 miesiąc",
  "12 miesiecy": "12 miesięcy",
  "24 miesiace": "24 miesiące",
  "panel www": "Panel WWW",
  "program lojalnosciowy polecaj imo": "Program lojalnościowy",
  "poradnik posrednika": "Poradnik pośrednika",
  "slownik pojec": "Słownik pojęć",
  "baza posrednikow": "Baza pośredników",
  aktualnosci: "Aktualności",
};
const fixedLinks = {
  'strona-www/korzysci': 'strona-www/korzysci/dla-wlasciciela-biura',
  'program-crm/korzysci': 'program-crm/korzysci/dla-posrednika',
  'program-crm/funkcje': 'program-crm/funkcje/praca-online',
  'pomoc/imo': 'pomoc/imo/konfiguracja-programu',
  'pomoc/panel-www': 'pomoc/panel-www/edycja-banera',
}
const customPaths = {
  '/strona-www/portfolio/': '/strona-www/portfolio/',
}

const TopTabs = ({ path, defaultTabItems = [] }) => {
  const [tabItems, setTabItems] = useState(defaultTabItems)
  const containerRef = useRef()
  const defaultPath = customPaths[path] || path?.startsWith('/zamowienie') ? 'zamówienie' : path

  const clearName = (name) => {
    return name
      .toLowerCase()
      .normalize("NFKD")
      .replace(/\u0142/g, "l")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/ /g, '-')
      .replace(/^.*".*&.*\..*:.*,.*-+.*$/, '');
  }

  useEffect(() => {
    containerRef.current.scrollLeft = containerRef.current.scrollWidth;
  }, [tabItems]);

  useEffect(() => {
    if (defaultPath) {
      const paths = defaultPath.split("/").filter((i) => i);
      const items = paths.map((i, idx) => {
        const label = i
          .split("-")
          .map((i) => (upperCaseWord.includes(i) ? i.toUpperCase() : i))
          .join(" ");
        const to = [...paths].splice(0, idx + 1).join("/");
        return {
          to: `/${fixedLinks[to] || clearName(to)}/`,
          label: breadcrumbsNames[label] || label,
        };
      });
      setTabItems([{ label: "Home", to: "/" }, ...items]);
    }
  }, [defaultPath]);

  return (
    <div
      className="w-full flex mt-8 overflow-auto min-h-[24px]"
      ref={containerRef}
    >
      {tabItems.map((i, idx) => {
        const isLastItem = idx === tabItems.length - 1;
        return (
          <div key={idx} className="flex items-center">
            {isLastItem ? (
              <p className="text-base whitespace-nowrap first-letter:capitalize">
                {i.label}
              </p>
            ) : (
              <Link
                to={i.to}
                className="text-blue hover:underline text-base whitespace-nowrap first-letter:capitalize"
              >
                {i.label}
              </Link>
            )}
            {!isLastItem && <ArrowSm className="child:fill-black mx-4 mt-1" />}
          </div>
        );
      })}
    </div>
  );
};

TopTabs.propTypes = {
  path: PropTypes.string,
  defaultTabItems: PropTypes.array,
}

export default TopTabs
